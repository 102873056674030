@use "functions";

// Base styles
// .tw namespace for all base styles to avoid collision with existing pages

html {
  scroll-behavior: smooth;
}

body {
  @apply bg-page-white antialiased;
  font-variant-numeric: lining-nums;
  font-size: 1rem;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: scroll;
  color: #232323;

  &.no-scroll {
    overflow: hidden;
  }

  // todo: determine best way to track implemented alternatives for accessibility
  * {
    -webkit-tap-highlight-color: transparent;
    &:focus {
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
  }
}

a,
.btn-link {
  @apply text-perriwinkle;
  &:hover,
  &:active,
  &:focus {
    color: #8199ff;
  }
}
.btn-link {
  @apply inline whitespace-normal;
}
a.unstyled-link {
  // Undo the color and underline at each link specificity level.
  &,
  &:hover,
  &:active,
  &:focus {
    @apply text-inherit no-underline;
  }
}

a.text-error:hover,
a.text-error:focus {
  color: #a71d2a;
  text-decoration: underline;
}

small,
.small {
  font-size: 0.8rem;
  line-height: 1.73;
}
.caps {
  text-transform: uppercase;
  font-feature-settings: "case";
}

h1 {
  font-size: functions.rem(27px);
  line-height: 1.4;
  font-weight: 700;
}
h2 {
  font-size: functions.rem(24px);
  line-height: 1.54;
  font-weight: 700;
}
h3 {
  font-size: functions.rem(20px);
  line-height: 1.4;
  font-weight: 400;
}
h4 {
  font-size: functions.rem(18px);
  line-height: 1.5;
  font-weight: 700;
}
h5 {
  font-size: functions.rem(16px);
  line-height: 1.65;
  font-weight: 500;
}

@screen sm {
  h1 {
    font-size: functions.rem(28px);
  }
  h2 {
    font-size: functions.rem(25px);
  }
  h3 {
    font-size: functions.rem(22px);
  }
  h4 {
    font-size: functions.rem(19px);
  }
  h5 {
    font-size: functions.rem(16px);
  }
}

@screen md {
  h1 {
    font-size: functions.rem(33.1px);
  }
  h2 {
    font-size: functions.rem(27.6px);
  }
  h3 {
    font-size: functions.rem(23px);
  }
  h4 {
    font-size: functions.rem(19.2px);
  }
  h5 {
    font-size: functions.rem(16px);
  }
}

@screen lg {
  h1 {
    font-size: functions.rem(39.1px);
    line-height: 1.33;
  }
  h2 {
    font-size: functions.rem(31.2px);
    line-height: 1.4;
  }
  h3 {
    font-size: functions.rem(25px);
    line-height: 1.48;
  }
  h4 {
    font-size: functions.rem(20px);
    line-height: 1.55;
  }
  h5 {
    font-size: functions.rem(16px);
    line-height: 1.65;
  }
}

.bg-baseGray {
  background: #fafafa;
}

.bg-baseGray-white {
  background: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
