@mixin font-setup($name, $style, $weight, $basename, $path) {
  @font-face {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    src: url("#{$path}#{$basename}.woff2") format("woff2"),
      url("#{$path}#{$basename}.woff") format("woff");
    font-display: swap;
  }
}

/** Avenir **/

@include font-setup(
  "avenir",
  normal,
  400,
  "Avenir-Roman",
  "#{$basePath}/static/fonts/avenir/"
);

@include font-setup(
  "avenir",
  normal,
  500,
  "Avenir-Medium",
  "#{$basePath}/static/fonts/avenir/"
);

@include font-setup(
  "avenir",
  italic,
  400,
  "Avenir-Oblique",
  "#{$basePath}/static/fonts/avenir/"
);

@include font-setup(
  "avenir",
  normal,
  700,
  "Avenir-Heavy",
  "#{$basePath}/static/fonts/avenir/"
);

@include font-setup(
  "avenir",
  italic,
  700,
  "Avenir-HeavyOblique",
  "#{$basePath}/static/fonts/avenir/"
);

@include font-setup(
  "avenir",
  normal,
  800,
  "Avenir-Black",
  "#{$basePath}/static/fonts/avenir/"
);

/** Hoefler Txt **/

@include font-setup(
  "hoefler",
  normal,
  400,
  "HoeflerTxt-Roman",
  "#{$basePath}/static/fonts/hoefler/"
);
@include font-setup(
  "hoefler",
  italic,
  400,
  "HoeflerTxt-Italic",
  "#{$basePath}/static/fonts/hoefler/"
);
@include font-setup(
  "hoefler",
  normal,
  700,
  "HoeflerTxt-Bold",
  "#{$basePath}/static/fonts/hoefler/"
);
@include font-setup(
  "hoefler",
  italic,
  700,
  "HoeflerTxt-BoldItalic",
  "#{$basePath}/static/fonts/hoefler/"
);

/** Hoefler Titling **/

@include font-setup(
  "hoefler-titling",
  normal,
  700,
  "HoeflerTitling-Bold",
  "#{$basePath}/static/fonts/hoeflerTitling/"
);

@include font-setup(
  "hoefler-titling",
  italic,
  700,
  "HoeflerTitling-BoldItalic",
  "#{$basePath}/static/fonts/hoeflerTitling/"
);
