.content-spacing {
  // Set max width so that the content (not padding) goes all the way to 1140px wide.
  max-width: calc(1140px + 6rem);
  @apply px-5 mx-auto;
  @screen sm {
    @apply px-12;
  }
  .exceed-content {
    margin-left: -1.25rem;
    margin-right: -1.25rem;

    @screen sm {
      margin-left: -3rem;
      margin-right: -3rem;
    }
  }
}

@responsive {
  .no-content-spacing {
    max-width: none;
    @apply px-0;
  }
}

.with-indicator {
  @apply pr-3;
  &::after {
    @apply inline-block ml-2 text-inherit border-current whitespace-no-wrap;
    content: "";
    border-width: 0.3rem;
    border-bottom-width: 0;
    border-right-color: transparent;
    border-left-color: transparent;
    vertical-align: 0.255rem;
  }
  &.open::after {
    border-bottom-width: 0.3rem;
    border-top-width: 0;
  }
}

.transform-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
