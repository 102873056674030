// Scroll animations

[class^="animation"] {
  -webkit-backface-visibility: hidden;
}

.animation-crossfade {
  opacity: 0;
  transition: opacity 700ms ease-in-out;
}

/* purgecss ignore */
.animation-crossfade.animation-active {
  opacity: 1;
}

.animation-initialized-fade {
  display: none;
}

.animation-initialized-fade {
  display: none;
}

.animation-fade-up-in {
  transform: translate3d(0, 50px, 0);
  opacity: 0;
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;

  &.delay1 {
    transition-delay: 200ms;
  }
  &.delay2 {
    transition-delay: 400ms;
  }
  &.delay3 {
    transition-delay: 600ms;
  }
  &.delay4 {
    transition-delay: 800ms;
  }
}
/* purgecss ignore */
.animation-fade-up-in.animation-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.animation-fade-left-in {
  transform: translate3d(50px, 0, 0);
  opacity: 0;
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;

  &.delay1 {
    transition-delay: 200ms;
  }
  &.delay2 {
    transition-delay: 400ms;
  }
  &.delay3 {
    transition-delay: 600ms;
  }
  &.delay4 {
    transition-delay: 800ms;
  }
}
/* purgecss ignore */
.animation-fade-left-in.animation-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.animation-fade-right-in {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;

  &.delay1 {
    transition-delay: 200ms;
  }
  &.delay2 {
    transition-delay: 400ms;
  }
  &.delay3 {
    transition-delay: 600ms;
  }
  &.delay4 {
    transition-delay: 800ms;
  }
}
/* purgecss ignore */
.animation-fade-right-in.animation-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes flashRating {
  0% {
    transform: scale(1);
    color: rgba(77, 75, 78, 0.25);
  }
  30% {
    transform: scale(1.5);
    color: #20d1cc;
  }
  50% {
    transform: scale(0.75);
  }
  65% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1);
    color: #20d1cc;
  }
  100% {
    color: #232323;
  }
}

.animation-flash-rating {
  color: #232323;
}

.isTargetBox .animation-flash-rating.animation-active {
  animation: 1.2s flashRating ease-out forwards;
  animation-delay: 0.2s;
}
