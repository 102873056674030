@layer components {
  .btn {
    @apply inline-block rounded border-2 whitespace-no-wrap leading-none no-underline;
    @apply transition duration-300;
    font-size: 16px;
    font-weight: 500;
    padding: 14px 30px;
  }

  .btn-sm {
    font-size: 14px;
    padding: 11px 22px;
  }

  .btn-lg {
    @screen lg {
      font-size: 18px;
      padding: 17px 38px;
    }
  }

  .btn-ink-black {
    @apply bg-ink-black text-white border-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-white text-ink-black;
    }
  }

  .btn-white {
    @apply bg-white text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-ink-black text-white;
    }
  }

  .btn-teal {
    @apply bg-teal border-teal text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-teal-light border-teal-light text-ink-black;
    }
  }

  .btn-perriwinkle,
  .btn-ink-black,
  .btn-white,
  .btn-teal {
    &:disabled {
      @apply bg-gray4 border-gray4 text-gray1;
    }
  }

  .btn-white-outline {
    @apply border-page-white text-white;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      @apply bg-page-white border-page-white text-ink-black;
    }

    &:disabled {
      @apply border-gray2 text-gray4;
    }
  }

  .btn-ink-black-outline {
    @apply border-ink-black text-ink-black;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      @apply bg-ink-black border-ink-black text-page-white;
    }

    &:disabled {
      @apply border-gray2 text-gray4;
      background-color: transparent;
    }
  }

  .btn-disabled {
    @apply bg-gray3 border-gray3 text-gray1;
  }

  .btn-coral {
    @apply bg-coral border-coral text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-coral border-coral text-page-white;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-cornflower {
    @apply bg-cornflower border-cornflower text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-cornflower border-cornflower text-page-white;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-ocean {
    @apply bg-ocean border-ocean text-page-white;
    &:hover,
    &:focus,
    &:active {
      @apply bg-ocean border-ocean text-gray2;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-sunrise {
    @apply bg-sunrise border-sunrise text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-sunrise border-sunrise text-page-white;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-mint {
    @apply bg-mint border-mint text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-mint border-mint text-page-white;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-forest {
    @apply bg-forest border-forest text-page-white;
    &:hover,
    &:focus,
    &:active {
      @apply bg-forest border-forest text-gray2;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-lavender {
    @apply bg-lavender border-lavender text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-lavender border-lavender text-page-white;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-plum {
    @apply bg-plum border-plum text-page-white;
    &:hover,
    &:focus,
    &:active {
      @apply bg-plum border-plum text-gray2;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-chartreuse {
    @apply bg-chartreuse border-chartreuse text-ink-black;
    &:hover,
    &:focus,
    &:active {
      @apply bg-chartreuse border-chartreuse text-page-white;
    }

    &:disabled {
      @apply btn-disabled;
    }
  }

  .btn-perriwinkle {
    @apply bg-perriwinkle border-perriwinkle text-page-white;
    &:hover,
    &:focus,
    &:active {
      @apply bg-perriwinkle-light border-perriwinkle-light;
    }
  }

  .btn-full {
    @apply w-full;

    @screen md {
      @apply w-auto;
    }
  }

  .btn-hero {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translate(0, 50%);
  }
}
