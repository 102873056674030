@use "sass:math";
@use "sass:list";
@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 5px !default;
$hamburger-padding-y: 0 !default;
$hamburger-layer-width: 33px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 1px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (spring) !default;

// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &[aria-expanded="true"] {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: math.div($hamburger-layer-height, -2);

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

@if list.index($hamburger-types, spring) {
  /*
   * Spring
   */
  .hamburger--spring {
    .hamburger-inner {
      top: $hamburger-layer-height * 0.5;
      transition: background-color 0s 0.13s linear;

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &[aria-expanded="true"] {
      .hamburger-inner {
        transition-delay: 0.22s;
        background-color: transparent !important;

        &::before {
          top: 0;
          transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(
              0,
              $hamburger-layer-spacing + $hamburger-layer-height,
              0
            )
            rotate(45deg);
        }

        &::after {
          top: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(
              0,
              $hamburger-layer-spacing + $hamburger-layer-height,
              0
            )
            rotate(-45deg);
        }
      }
    }
  }
}
